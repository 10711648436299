<template>
  <el-dialog
      title=""
      :visible.sync="show"
      width="30%"
      :before-close="handleClose">
    <avue-form ref="form"
               v-model="form"
               :option="formOption"
               @submit="handleSubmit">
      <template slot="menuForm">
        <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
      </template>
    </avue-form>
  </el-dialog>
</template>

<script>
import {examine, examineBatch} from "@/api/new/house";

export default {
  name: "examine",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    examine: {
      type: Object,
      default: () => {}
    },
    multipleSelection: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return{
      form: {},
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: '审核',
            prop: 'applyState',
            type: 'radio',
            value: 3,
            span: 24,
            dicData: [
              {
                label: '审核通过',
                value: 3
              },
              {
                label: '审核驳回',
                value: 4
              }
            ],
            rules: [
              {
                required: true,
                message: '请选择审核结果',
                trigger: 'change'
              }
            ]
          },
          {
            label: '返还蜜豆数量',
            prop: 'actualHoney',
            type: 'number',
            minRows: 100,
            maxRows: 999999999999,
            controls: false,
            span: 24,
            rules: [
              {
                required: true,
                message: '请输入返还蜜豆数量',
                trigger: 'blur'
              }
            ],
            display: true
          },
          {
            label: '审核说明',
            prop: 'applyExamineExplain',
            span: 24,
            type: 'textarea',
            maxlength: 30,
            showWordLimit: true,
          }
        ]
      }
    }
  },
  watch:{
    'form.applyState'(val){
      const actualHoney = this.findObject(this.formOption.column,'actualHoney')
      if(val === 4){
        actualHoney.display = false;
      }else{
        actualHoney.display = true;
      }
    }
  },
  methods: {
    handleSubmit(form,done){
      if(this.examine.type === 'one'){
        examine({
          id: this.examine.id,
          ...form
        }).then(res => {
          if(res.code === 200){
            this.$message.success('操作成功');
            this.$emit('submit')
          }else{
            this.$message.error(res.msg)
            done();
          }
        }).catch(()=> done())
      }else{
        examineBatch({
          ids: this.multipleSelection,
          ...form
        }).then(res => {
          if(res.code === 200){
            this.$message.success('操作成功');
            this.$emit('submit')
          }else{
            this.$message.error(res.msg)
            done();
          }
        }).catch(()=> done())
      }
    },
    handleClose(){
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>