<template>
  <el-dialog
      title="查看"
      :visible.sync="show"
      width="50%"
      :before-close="handleClose">
    <avue-form ref="form"
               v-model="form"
               :option="formOption"
               @submit="handleSubmit">
      <template slot="titleOne">
        <h3>用户信息</h3>
      </template>
      <template slot="titleTwo">
        <h3>房源信息</h3>
      </template>
      <template slot="titleThree">
        <h3>申请信息</h3>
      </template>
      <template slot="code">
        <div>{{ form.code }}</div>
      </template>
      <template slot="nickname">
        <div>{{ form.nickname }}</div>
      </template>
      <template slot="authUsername">
        <div>{{ form.authUsername }}</div>
      </template>
      <template slot="phone">
        <div>{{ form.phone }}</div>
      </template>
      <template slot="houseCode">
        <div>{{ form.houseCode }}</div>
      </template>
      <template slot="title">
        <div>{{ form.title }}</div>
      </template>
      <template slot="maxFloorage">
        <div>{{ form.maxFloorage === form.minFloorage ? form.minFloorage : form.minFloorage + ' — ' + form.maxFloorage }}</div>
      </template>
      <template slot="housePrice">
        <div>{{ form.housePrice }}</div>
      </template>
      <template slot="estimateHoney">
        <div>{{ form.estimateHoney }}</div>
      </template>
      <template slot="actualHoney">
        <div>{{ form.actualHoney }}</div>
      </template>
      <template slot="menuForm">
        <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
      </template>
    </avue-form>
  </el-dialog>
</template>

<script>
export default {
  name: "detail",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    applyState: {
      type: Number,
      default: () => 2
    },
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return{
      form: this.detail,
      formOption: {
        submitText: '审核',
        submitBtn: this.applyState === 2 ? null : false,
        emptyBtn: false,
        labelWidth: 150,
        disabled: true,
        column: [
          {
            label: '',
            prop: 'titleOne',
            span: 24,
            row: true,
            labelWidth: 30
          },
          {
            label: '用户ID',
            prop: 'code',
            span: 12
          },
          {
            label: '用户昵称',
            prop: 'nickname',
            span: 12
          },
          {
            label: '用户姓名',
            prop: 'authUsername',
            span: 12
          },
          {
            label: '用户手机号',
            prop: 'phone',
            span: 12
          },
          {
            label: '',
            prop: 'titleTwo',
            span: 24,
            row: true,
            labelWidth: 30
          },
          {
            label: '房源号',
            prop: 'houseCode',
            span: 12
          },
          {
            label: '房源名称',
            prop: 'title',
            span: 12
          },
          {
            label: '建筑面积(m²)',
            prop: 'maxFloorage',
            span: 12
          },
          {
            label: '',
            prop: 'titleThree',
            span: 24,
            row: true,
            labelWidth: 30
          },
          {
            label: '房源价格(元)',
            prop: 'housePrice',
            span: 24
          },
          {
            label: '预计返款数量',
            prop: 'estimateHoney',
            span: 24
          },
          {
            label: '合同相应附件',
            prop: 'contractImages',
            type: 'upload',
            span: 24,
            dataType: 'string',
            listType: 'picture-card',
            objectFit: 'cover'
          },
          {
            label: '返还蜜豆数量',
            prop: 'actualHoney',
            span: 12,
            display: this.applyState !== 2,
          }
        ]
      }
    }
  },
  methods: {
    handleSubmit(form,done){
      done();
      this.$emit('submit')
    },
    handleClose(){
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail{
  object-fit: contain;
}
</style>