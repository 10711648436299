import {get, put} from "@/api/axios";

export const list = params => get('/web/new/house/list', params);

// /web/new/house/examine put
export const examine = params => put('/web/new/house/examine', params)

// /web/new/house/examine/batch put
export const examineBatch = params => put('/web/new/house/examine/batch', params)

// /web/new/house/view/{id} get
export const view = params => get('/web/new/house/view/' + params.id, '')