<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
        @selection-change="selectionChange"
    >
      <template #header>
        <div class="flex align-items marginBottom10 flex-wrap">
          <el-input size="medium" v-model="searchData.username" :maxlength="100" placeholder="输入客户姓名" class="marginRight10 width300 marginBottom10"/>
          <el-input size="medium" v-model="searchData.phone" :maxlength="100" placeholder="输入客户手机号" class="marginRight10 width300 marginBottom10"/>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.applyState" clearable>
              <el-option label="审核状态" value=""></el-option>
              <el-option label="待审核" :value="2"></el-option>
              <el-option label="审核通过" :value="3"></el-option>
              <el-option label="审核驳回" :value="4"></el-option>
            </el-select>
          </div>
          <el-input size="medium" v-model="searchData.title" placeholder="输入房源名称" class="marginRight10 width300 marginBottom10"/>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-receiving" @click="handleAllToExamine">批量审核</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" style="background: #c280ff;color: #ffffff;border-color: #c280ff" icon="el-icon-download" @click="handleExport">导出</el-button>
          </div>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <!--        查看-->
        <el-button size="mini" type="text" icon="el-icon-view" @click="handleView(row)">查看</el-button>
        <!--        审核-->
        <el-button size="mini" type="text" v-if="row.applyState === 2" icon="el-icon-connection" @click="handleExamine(row)">审核</el-button>
      </template>
    </avue-crud>
    <Examine v-if="showExamine" :show="showExamine" :examine="examine" :multipleSelection="multipleSelection" @submit="submitExamine" @close="closeExamine"/>

    <Detail v-if="showDetail" :show="showDetail" :detail="detail" :applyState="applyState" @submit="submitDetail" @close="closeDetail"/>
  </div>
</template>

<script>
import { list,view } from "@/api/new/house"
import Examine from "./examine";
import Detail from "./detail"
import qs from "qs";
export default {
  name: "index",
  components: {
    Examine,
    Detail
  },
  data(){
    return{
      searchData: {
        username: '',
        phone: '',
        applyState: '',
        title: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '新房管理',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: '序号',
        indexWidth: 100,
        size: 'small',
        selection: true, // 显示多选框
        selectable:(row)=>{
          return row.applyState === 2;
        },
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '提交时间',
            prop: 'applyTime',
            width: 200
          },
          {
            label: '用户昵称',
            prop: 'nickname',
            width: 150
          },
          {
            label: '用户手机号',
            prop: 'phone',
            width: 150
          },
          {
            label: '用户姓名',
            prop: 'authUsername',
            width: 150
          },
          {
            label: '房源号',
            prop: 'houseCode',
            width: 250
          },
          {
            label: '房源名称',
            prop: 'title',
            width: 150
          },
          {
            label: '返佣比例',
            prop: 'rebateScale',
            formatter: (row,column,cellValue)=>{
              return `${cellValue}%`
            },
            width: 150
          },
          {
            label: '支付方分账比例',
            prop: 'payerScale',
            formatter: (row,column,cellValue)=>{
              return `${cellValue}%`
            },
            width: 150
          },
          {
            label: '审核状态',
            prop: 'applyState',
            formatter: (row,column,cellValue)=>{
              return cellValue === 2 ? '待审核' : cellValue === 3 ? '审核通过' : cellValue === 4 ? '审核驳回' : ''
            },
            width: 150
          },
          {
            label: '审核时间',
            prop: 'applyExamineTime',
            width: 200
          },
          {
            label: '审核说明',
            prop: 'applyExamineExplain',
            width: 200
          }
        ]
      },
      multipleSelection: [],

      showExamine: false,
      examine: {
        id: '',
        type: 'one'
      },

      showDetail: false,
      applyState: 2,
      detail: {}
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 查询
    handleSearch(){
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset(){
      // console.log('重置')
      this.searchData = {
        username: '',
        phone: '',
        applyState: '',
        title: ''
      }
      this.page.currentPage = 1;
      this.onLoad();
    },

    // 查看
    async handleView(row){
      // console.log('查看',row)
      this.examine = {
        id: row.id,
        type: 'one'
      }
      this.applyState = row.applyState;
      const { data } = await view({id: row.id})
      // console.log(data)
      this.detail = data;
      this.showDetail = true;
    },
    // 审核
    handleExamine(row){
      // console.log('审核',row)
      this.showExamine = true;
      this.examine = {
        id: row.id,
        type: 'one'
      }
    },
    async submitExamine(){
      this.showExamine = false;
      this.showDetail = false;
      await this.onLoad();
    },
    closeExamine(){
      this.showExamine = false;
    },
    submitDetail(){
      this.showExamine = true;

      // this.showDetail = false;
      // this.onLoad();
    },
    closeDetail(){
      this.showDetail = false;
    },
    // 多选
    selectionChange(val){
      // console.log(val)
      this.multipleSelection = val.map(item=>item.id);
      // console.log(this.multipleSelection)
    },
    handleAllToExamine(){
      // console.log('批量审核')
      if(this.multipleSelection.length === 0){
        this.$message.warning('请选择需要审核的数据')
      }else{
        this.showExamine = true;
        this.examine = {
          id: '',
          type: 'all'
        }
      }
    },
    // 导出
    handleExport(){
      // console.log(qs.stringify(this.searchData))
      window.open(`/api/web/new/house/export?${qs.stringify(this.searchData)}`,'_self');
    }
  }
}
</script>

<style scoped>
/deep/ .avue-crud .avue-crud__tip{
  display: none;
}
</style>